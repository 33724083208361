import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/collections/past-due',
    name: 'Past Due Balances Report',
    component: () => import('../components/reports/collections/pastDue.vue')
  },
  {
    path: '/balances',
    name: 'Past Due Balances',
    component: () => import('../components/expired_sales/table.vue')
  },
  {
    path: '/sales-to-authorize',
    name: 'Sales To Authorize',
    component: () => import('../components/auth_sales/table.vue')
  },
  {
    path: '/bank_movements',
    name: 'Bank Movements',
    component: () => import('../components/bank_movements/table.vue')
  },
  {
    path: '/influencers',
    name: 'Influencers',
    component: () => import('../components/influencers/container.vue')
  },
  {
    path: '/contracts',
    name: 'Contratos',
    component: () => import('../components/contracts/table.vue')
  },
  {
    path: '/expenses',
    name: 'Gastos',
    component: () => import('../components/expenses/table.vue')
  },
  {
    path: '/influencers-payments',
    name: 'Influencers Payments',
    component: () => import('../components/influencers_payments/table.vue')
  },
  {
    path: '/influencers/influencer/:influencer_id',
    name: 'influencerDetail',
    component: () => import('../components/influencers/influencerDetail.vue')
  },
  {
    path: '/',
    name: 'Sales',
    component: () => import('../components/sales/table.vue')//component: () => import('../components/dashboard/container.vue')
  },

  {
    path: '/sales/total-report',
    name: 'Sales Report',
    component: () => import('../components/reports/sales/salesByMonth.vue')
  },
  {
    path: '/sales/influencers-reports',
    name: 'Sales Report',
    component: () => import('../components/reports/sales/salesByInfluencers.vue')
  },
  {
    path: '/sales/agencies-reports',
    name: 'Sales Report',
    component: () => import('../components/reports/sales/salesByAgencies.vue')
  },
  {
    path: '/sales/department-reports',
    name: 'Sales Report',
    component: () => import('../components/reports/sales/salesBKvsMGMT.vue')
  },
  {
    path: '/sales/users-reports',
    name: 'Sales Report',
    component: () => import('../components/reports/sales/salesByUser.vue')
  },
  
  {
    path: '/registers/total-report',
    name: 'Sales Register Report',
    component: () => import('../components/reports/registers/salesByMonth.vue')
  },
  {
    path: '/registers/expired-sales-report',
    name: 'Expired Sales Report',
    component: () => import('../components/reports/registers/expiredSalesByMonth.vue')
  },
  {
    path: '/registers/influencers-reports',
    name: 'Sales Register Report',
    component: () => import('../components/reports/registers/salesByInfluencers.vue')
  },
  {
    path: '/registers/agencies-reports',
    name: 'Sales Register Report',
    component: () => import('../components/reports/registers/salesByAgencies.vue')
  },
  {
    path: '/registers/department-reports',
    name: 'Sales Register Report',
    component: () => import('../components/reports/registers/salesBKvsMGMT.vue')
  },
  {
    path: '/registers/users-reports',
    name: 'Sales Register Report',
    component: () => import('../components/reports/registers/salesByUser.vue')
  },


  {
    path: '/collections/total-report',
    name: 'Collections Report',
    component: () => import('../components/reports/collections/salesByMonth.vue')
  },
  {
    path: '/collections/influencers-reports',
    name: 'Collections Report',
    component: () => import('../components/reports/collections/salesByInfluencers.vue')
  },
  {
    path: '/collections/agencies-reports',
    name: 'Collections Report',
    component: () => import('../components/reports/collections/salesByAgencies.vue')
  },
  {
    path: '/collections/department-reports',
    name: 'Collections Report',
    component: () => import('../components/reports/collections/salesBKvsMGMT.vue')
  },
  {
    path: '/collections/users-reports',
    name: 'Collections Report',
    component: () => import('../components/reports/collections/salesByUser.vue')
  },

  {
    path: '/quotations',
    name: 'Quotations',
    component: () => import('../components/quotations/table.vue')
  },
  {
    path: '/sales',
    name: 'Sales',
    component: () => import('../components/sales/table.vue')
  },
  {
    path: '/sales/:sale_id',
    name: 'Sales',
    component: () => import('../components/sales/table.vue')
  },
  {
    path: '/canceled',
    name: 'Canceled', 
    component: () => import('../components/canceled/table.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../components/settings/container.vue')
  },
  {
    path: '/clients',
    name: 'Clients',
    component: () => import('../components/clients/table.vue')
  },
  {
    path: '/verify-agencies',
    name: 'Verify Agencies',
    component: () => import('../components/clients/companies/agencies/verify.vue')
  },
  {
    path: '/activity-log',
    name: 'Activity Log',
    component: () => import('../components/activitylog/container.vue')
  },
  {
    path: '/clients/agency/:agency_id',
    name: 'agencyDetail',
    component: () => import('../components/clients/companies/agencies/detail.vue')
  },
  {
    path: '/clients/brand/:brand_id',
    name: 'brandDetail',
    component: () => import('../components/clients/companies/brands/detail.vue')
  },
  {
    path: '/clients/agency/:id',
    name: 'agencyDetail',
    component: () => import('../components/clients/companies/agencies/detail.vue')
  },
  {
    path: '/clients/brand/:id',
    name: 'brandDetail',
    component: () => import('../components/clients/companies/brands/detail.vue')
  },
  {
    path: '/notes',
    name: 'Notes',
    component: () => import('../components/notes/container.vue')
  },
  {
    path: '/adjustments',
    name: 'Adjustments',
    component: () => import('../components/adjustments.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
